@import url('./fonts/app-font.css');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Fira Sans', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-box {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #0000003b;
}

.blue {
  background-color: #31d2ff !important;
}
.green {
  background-color: #8cff6f;
}
.orange {
  background-color: #fda437;
}

.Main {
  display: flex;
  gap: 10px;
  justify-content: start;
  margin-bottom: 30px;
  background: white;
  box-shadow: 0px 0px 10px #dddddd49;
  padding: 20px;
  border-top: 1px solid #dddddd7d;
  flex-wrap: wrap;
}
.DivColor {
  display: flex;
  justify-content: center !important;
  margin-bottom: 20px;
}
.Main button {
  border: none;
  background-color: transparent;
}

.form-group__element.font_selector_dropdown {
  max-width: 850px;
  margin: 0 auto;
}

/* new-css */

h1.main-container__header {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 5px;
  color: #084e93;
  text-transform: uppercase;
}

.header_title_div {
padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}

p.main-container__subheader {
  font-size: 16px;
  font-weight: 400;
  color: #084e93;
}

.container_card {
  border: 1px solid #cccccc70;
  max-width: 900px;
  margin: 0 auto;
  background: white;
  padding: 20px 60px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #dddddd3b;
  margin-top: 10px;
}
.form-group {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.custome_color_picker input[type="color"] {
  width: 100%;
  height: 130px;
  border: none;
  border-radius: 25px;
  /* overflow: hidden; */
  background: none;
  border-radius: 10px 10px 0px 0px !important;
  transform: scale(1.1);
  cursor: pointer;
}
.custome_color_picker {
  overflow: hidden;
  width: 100%;
  height: 125px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 0px 10px #dddddd8c;
}

button.buttons__button.buttons__button--next.btn_next {
  display: block;
  margin-left: auto;
}

.Add_file_div input {
  display: none;
}

.form-group__input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: transparent;
  height: 40px;
}

.Add_file_div label.form-group__label {
  font-size: 14px;
  color: #084e93;
  text-align: center;
  background: rgb(198 220 255 / 33%);
  display: inline-block !important;
  max-width: 90px;
  border-radius: 5px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

.Add_file_div {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #084e93;
  max-width: 850px;
  background: rgb(43 124 255 / 4%);
  margin: 0 auto 20px;
  border-radius: 5px;
}

.form-group.Main_group_log {
  max-width: 700px;
  width: 100% !important;
}

label.form-group__label span.material-symbols-outlined {
  font-size: 16px;
}

h4 {
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: bold;
}

h5 {
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: bold;
}

.StepperContainer-0-2-1 {
  margin-bottom: 30px;
}

.form-group__element.Pick_logo_div {
  max-width: 100%;
  margin: 0 auto;
  background: white;
  padding: 20px 20px;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px #dddddd3b; */
  margin-top: 20px;
}

.container_card p {
  font-size: 16px;
  color: #222;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 6px;
  text-align: start;
}
.container_card ul {
  padding: 0;
  text-align: start;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.container_card ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #222;
  line-height: 22px;
}

.container_card ul li span.material-symbols-outlined {
  font-size: 18px;
}

a.btn.btn-general {
  font-size: 16px;
  background: #084e93;
  display: block;
  padding: 10px 20px;
  text-align: start;
  max-width: 185px;
  display: flex;
  justify-content: center; 
  gap: 5px;
  text-decoration: none;
  color: white;
  align-items: center;
  border-radius: 5px;
  margin: 20px auto;
  cursor: pointer;
}

a.btn.btn-general img {
  width: 25px;
  height: auto;
  object-fit: contain;
}

.button_div {
  max-width: 900px;
  margin-left: auto;
  display: block;
  margin: 0px auto;
  padding-bottom: 30px;
}

.div_business {
  max-width: 850px;
  width: 100%;
  margin: 20px auto;
}

.color_choose .form-group__element {
  margin: 10px auto;
  gap: 20px;
  max-width: 50%;
}
.color_choose {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.Payment_Method {
  display: flex;
  gap: 20px;
}
.payment_input_div {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 135px;
  width: 100%;
  box-shadow: 0px 0px 10px #80808057;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  flex-direction: column;
}

.payment_input_div label.form-group__label {
  margin: 0;
}

.payment_input_div div {
  display: flex;
  gap: 10px;
}
.payment_input_div img {
  width: 45px;
}
.container_card.Store_Details .div_business {
  margin-bottom: 20px;
}

.div_business.Product_Display input[type="radio"] {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 5px;
}

.payment_input_div input[type="radio"] {
  height: 25px;
  width: 25px;
  margin: 0;
}
.container_card.Successfully img {
  width: 130px;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.container_card.Successfully label.form-group__label {
  text-align: center;
  font-size: 20px;
}

.custom-loader {
  display: flex;
  position: fixed;
  top: 0;
  background: #000000a8;
  width: 100%;
  height: 100vh;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.View_div img {
  width: 20px;
}
.pro_select {
  background: #dddddd !important;
}

.View_div {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 100px;
  background: white;
  box-shadow: 0px 0px 10px #ddd;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
}
.main_view_card_div {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 12px;
}

.div_business.Product_Display label {
  text-align: center;
}

.div_business.Product_Display input[type="radio"] {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 5px;
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0;
}

.guidline {
  cursor: pointer;
  color: #4064bd;
  font-size: 14px;
}

div.guidline-popup-content ul li {
  list-style: none;
  display: flex;
  align-items: start;
}

li.allow_li {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
li.allow_li div {
  display: flex;
}

ul.Scope_ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px !important;
  padding-left: 35px !important;
}

div.guidline-popup-content ul {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 20px;
  overflow: auto;
  max-height: 750px;
  background-color: #4064bd12;
  max-width: 95%;
  margin: 0px 20px 20px;
}


/* width */
div.guidline-popup-content ul::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
div.guidline-popup-content ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.356); 

}
 
/* Handle */
div.guidline-popup-content ul::-webkit-scrollbar-thumb {
  background: #084e93; 
}









ul.Scope_ul {
  background-color: transparent !important;
}

ul.Scope_ul li {
  font-size: 14px;
  color: #202223;
  list-style-type: disc !important;
  display: list-item !important;
}
.popup-content {
  padding: 0px !important;
  border-radius: 5px;
  width: 820px;
}

.guidline-popup-content h4 {
  max-width: 100%;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 auto;
  text-transform: capitalize;
  display: flex;
  gap: 50px;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}
.popup_title {
  padding: 20px;
}
h4 span {
  background: rgb(0, 58, 107);
  border-radius: 50px;
  padding: 2px;
  color: white;
  font-size: 20px !important;
  cursor: pointer;
}

ul.Scope_ul ul {
  background: transparent !important;
  padding: 0px;
  margin: 0px auto;
}
div.guidline-popup-content ul li span {
  font-size: 22px;
}

ul.Scope_ul span {
  font-size: 20px !important;
  display: none;
}

.brandbldr-icon {
  width: 40px;
  height: 40px;
  margin: 20px;
}

.verify_token {
  display: flex;
}

.notesBox {
  color: #dd4c4c;
  font-size: 14px;
  font-style: italic;
}

.skip-title {
  padding: 10px;
}

.Pick_logo_div {
  padding-inline: 0 !important;
}
p.pick_logo_text {
  margin-bottom: 10px;
  font-weight: 400;
  color: #222;
  font-size: 16px;
}

.colorBoxDiv {
  display: flex;
  gap: 20px;
}
.colorBoxDiv input[type="color"] {
  background: #fff;
  padding: 0 3px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #dddddd49;
  height: 70px;
  width: 70px;
}

.step2_formgroup h4 {
  text-align: left;
}
.step2_formgroup {
  margin-left: 0;
  max-width: 100%;
}
.step2_formgroup a.btn.btn-general {
  margin: 0px 5px 5px;
}

a {
  text-decoration: none;
  color: #4064bd;
}


.step-box-custom {
  max-width: 100%;
  padding: 30px 0px;
  height: auto;
  cursor: pointer;
}

.title-info {
  display: flex;
  flex-direction: column;
}


.option-box-1 {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 100px;
  background: white;
  box-shadow: 0px 0px 10px #ddd;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  grid-gap: 10px;
  gap: 10px;
}


input#storeLogo {
  display: none;
}

.main-box {
  display: flex;
  gap: 20px;
}
.option-box-1 label {
  font-size: 14px;
  color: #202223;
  padding: 5px 10px;
  border-radius: 5px;
}

.option-box-1 a {
  font-size: 14px;
  color: #202223;
}


.option-box-3.input-checkbox {
  padding: 20px 0px;
  text-align: end;
}
.option-box-1 img {
  width: 25px;
}

.option-box-1 label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.option-box-1 a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;

}


.input-checkbox .d-flex {
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  display: flex;
}

label.skip-title {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
label.skip-title  input.form-group {
  width: 14px;
  height: 14px;
  max-width: 20px;
  margin: 0;
}

.skip-title input[type="checkbox"] {
  display: none;
}


div.guidline-popup-content ul.Scope_ul {
  margin: 0;
  padding: 0 0 0 60px !important;
  max-width: 100%;
  width: 100%;
}
div.guidline-popup-content ul.Scope_ul li.innerList-box {
  list-style: none !important;
  position: relative;
}
div.guidline-popup-content ul.Scope_ul li.innerList-box::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #202223;
  position: absolute;
  left: -13px;
  top: 5px;
}
div.guidline-popup-content ul.Scope_ul li.innerList-box ul {
  padding: 5px 0 0 20px;
  margin: 0;
}
div.guidline-popup-content ul.Scope_ul li.innerList-box ul li.innerList-box::before {
  border-radius: 50%;
  top: 8px;
}


.step-box-custom-1 {
  max-width: 100%;
  padding: 30px 0px;
  height: auto;
  width: auto !important;
}

.container_card_1 ul {
  padding: 15px !important;
}

.container_card.Successfully label.form-group__label2 {
  font-size: 18px !important;
}

.container_card.Successfully label.form-group__label3 {
  font-size: 17px !important;
  padding: 0px 100px 40px;
}

.whats_next_box {
  padding: 0px 15px;
}

.whats_next_box h4 {
  margin-bottom: 0px;
}


.next-li {
  position: relative;
  padding-left: 20px; /* Adjust the padding as needed */
  margin-bottom: 10px; /* Adjust the margin as needed */
}


.custom-text {
  padding: 0px 25px;
}

.MuiIconButton-root {
  padding: 5px !important;
}

.step2_formgroup li b {
  margin-right: 10px;
}

li.next-li {
  list-style: disc;
  display: list-item !important;
  padding: 0;
  margin-left: 20px;
}
.container_card.container_card_1.Successfully p {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  font-size: 14px;
}

.form-group__element h4 {
  font-size: 32px;
  margin-bottom: 16px;
}
.container_card.container_card_1.Successfully {
  display: flex;
  align-items: center;
  max-width: 1140px;
  padding: 0;
}


.container_card.container_card_1.Successfully .form-group__element {
  background: #0049ff14;
  margin: 0;
  padding: 50px 20px;
  width: 100%;
  max-width: 50%;
}


.btn-section a.btn.btn-general {
  max-width: max-content;
  margin: 30px auto;
  padding: 15px 35px;
}


.logo-section {
  text-align: center;
}
.main-store-section {
  padding-top: 20vh;
}

.header_title_div img {
  width: 105px;
  padding: 10px;
}

.main_welcome_screen {
  width: 100%;
  margin: 0 auto;
  background-color: #e9f8fe71;
  min-height: 100vh;
  background-image: url('./images/store-logo/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  backdrop-filter: blur(10px);
}
.logo-section img {
  width: 400px;
}

.RFS-StepperContainer {
  max-width: 900px;
  margin: 0 auto;
}

.div_business a.btn.btn-general {
 
  margin: 10px 0px;
}
.container_card h4 {
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #084e93;
  margin-bottom: 20px;
  font-weight: 500;

}



.searchWrapper {
  width: 100%;
  padding: 10px;
  border-radius: 3px; 
  border: 1px solid #ddd;
  background-color: transparent;
  height: auto;
}

.step2_formgroup.subscribe-box h4 {
  display: flex;
  justify-content: end;
}


.div_subscribe a.btn.btn-general {
  margin-left: auto !important;
  text-align: end;
  width: fit-content;
  margin-top: 20px !important;
  margin-right: 0 !important;
  cursor: pointer;
}
.step2_formgroup.subscribe-box {
  padding: 20px 20px 30px;
}



.MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
  font-size: 14px !important;

}
 button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0 !important;
}


/* .div_business.sub-plan-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #084e930a;
  border-radius: 10px;
  border: 2px solid #084e936e;
} */

.step2_formgroup1 {
  align-items: center;
  padding: 20px;
  background: #084e930a;
  border-radius: 10px;
  border: 2px solid #084e936e;
}